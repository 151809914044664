/* eslint-disable no-shadow */
import { computed, readonly, ref } from 'vue';
import { domain } from '@/util/domainUtils';

// AKS
import aksDemo from '@/configs/aks/demo.json';
import aksDev from '@/configs/aks/dev.json';
import aksTest from '@/configs/aks/test.json';
// SE
import akademikerforsakring from '@/configs/se/akademikerforsakring.json';
import seDev from '@/configs/se/dev.json';
import seProd from '@/configs/se/prod.json';
import raksystems from '@/configs/se/raksystems.json';
import digitaltjanstforjuridiskadokument from '@/configs/se/digitaltjanstforjuridiskadokument.json';
import sustera from '@/configs/se/sustera.json';
// UK
import ukDev from '@/configs/uk/dev.json';
import documentcentre from '@/configs/uk/documentcentre.json';
import ukProd from '@/configs/uk/prod.json';

import ieProd from '@/configs/ie/prod.json';
import itProd from '@/configs/it/prod.json';
import itProdPos from '@/configs/it/pos.json';

import auProd from '@/configs/au/prod.json';
import auProdMain from '@/configs/au/prod-main.json';
import slProd from '@/configs/sl/prod.json';

export enum ARAG_DOMAINS {
  AKS_DEMO = 'demo.aks.dc.arag.com',
  AKS_DEV = 'dev.aks.dc.arag.com',
  AKS_TEST = 'test.aks.dc.arag.com',
  SE_AKADEMIKERFORSAKRING = 'akademikerforsakring.arag.se',
  SE_DEV = 'se.dev.aks.dc.arag.com',
  SE_PROD = 'se.prod.aks.dc.arag.com',
  SE_RAKSYSTEMS = 'raksystems.arag.se',
  SE_DIGITALTJANSTFORJURIDISKADOKUMENT = 'digitaltjanstforjuridiskadokument.arag.se',
  SE_SUSTERA = 'sustera.arag.se',
  UK_DEV = 'uk.dev.aks.dc.arag.com',
  UK_DOCUMENTCENTER = 'documentcentre.arag.co.uk',
  UK_PROD = 'uk.prod.aks.dc.arag.com',
  IE_PROD = 'ie.prod.aks.dc.arag.com',
  IE_DOCUMENTCENTER = 'legaldocuments.arag.ie',
  IT_PROD = 'it.prod.aks.dc.arag.com',
  IT_PROD_NEW = 'documentcenter.arag.it',
  IT_PROD_POS = 'documentcenterpos.arag.it',
  AU_PROD = 'au.prod.aks.dc.arag.com',
  AU_PROD_NEW = 'documentcentre-new.arag.com.au',
  AU_PROD_MAIN = 'documentcentre.arag.com.au',
  SL_PROD = 'sl.prod.aks.dc.arag.com',
}

export default function useConfigs() {
  const environment = ref('');

  const configs = computed(() => {
    switch (domain) {
      case ARAG_DOMAINS.AKS_DEMO:
        environment.value = 'aks';
        return aksDemo;
      case ARAG_DOMAINS.AKS_DEV:
        environment.value = 'aks';
        return aksDev;
      case ARAG_DOMAINS.AKS_TEST:
        environment.value = 'aks';
        return aksTest;
      case ARAG_DOMAINS.SE_AKADEMIKERFORSAKRING:
        environment.value = 'se';
        return akademikerforsakring;
      case ARAG_DOMAINS.SE_DEV:
        environment.value = 'se';
        return seDev;
      case ARAG_DOMAINS.SE_PROD:
        environment.value = 'se';
        return seProd;
      case ARAG_DOMAINS.SE_RAKSYSTEMS:
        environment.value = 'se';
        return raksystems;
      case ARAG_DOMAINS.SE_DIGITALTJANSTFORJURIDISKADOKUMENT:
        environment.value = 'se';
        return digitaltjanstforjuridiskadokument;
      case ARAG_DOMAINS.SE_SUSTERA:
        environment.value = 'se';
        return sustera;
      case ARAG_DOMAINS.UK_DEV:
        environment.value = 'uk';
        return ukDev;
      case ARAG_DOMAINS.UK_DOCUMENTCENTER:
        environment.value = 'uk';
        return documentcentre;
      case ARAG_DOMAINS.UK_PROD:
        environment.value = 'uk';
        return ukProd;
      case ARAG_DOMAINS.IE_PROD:
      case ARAG_DOMAINS.IE_DOCUMENTCENTER:
        environment.value = 'ie';
        return ieProd;
      case ARAG_DOMAINS.IT_PROD:
      case ARAG_DOMAINS.IT_PROD_NEW:
        environment.value = 'it';
        return itProd;
      case ARAG_DOMAINS.IT_PROD_POS:
        environment.value = 'it';
        return itProdPos;
      case ARAG_DOMAINS.AU_PROD_NEW:
      case ARAG_DOMAINS.AU_PROD_MAIN:
        environment.value = 'au';
        return auProdMain;
      case ARAG_DOMAINS.AU_PROD:
        environment.value = 'au';
        return auProd;
      case ARAG_DOMAINS.SL_PROD:
        environment.value = 'sl';
        return slProd;
      default:
        environment.value = 'localhost';
        return aksDev;
    }
  });

  return {
    ...readonly(configs.value),
    environment,
  };
}
